import { ProgressCircle, Site } from "@equiem/web-ng-lib";
import color from "color";
import React, { useContext } from "react";

export interface Props {
  focusable?: boolean;
  disabled?: boolean;
  loading: boolean;
  shadow: "hover" | "always";
  label?: string;
  labelClassName?: string;
  value: boolean;
  className: string;
  onToggle: () => Promise<void>
  children?: React.ReactNode;
}

export const RoundButton: React.FC<Props> = ({
  focusable,
  disabled,
  loading,
  shadow,
  className,
  value,
  onToggle,
  children,
  label,
  labelClassName,
}) => {
  const site = useContext(Site);
  const bgHex = value ? site.primaryColour : "#fff";
  const fgHex = value ? site.primaryContrastColour : site.whiteContrastColour;
  const shadowColorHex = site.primaryContrastColour;
  const shadowColor = color(shadowColorHex);
  const disabledClass = disabled === true ? "disabled" : "";

  // tslint:disable-next-line: no-empty
  const action = disabled === true ? () => {} : onToggle;

  return (
    <>
      <a
        className={`${
          className ?? ""
        } ${disabledClass} btn-round hide-in-percy d-flex flex-column justify-content-center text-center align-items-center rounded rounded-circle ${
          value ? "on" : "off"
        } ${shadow}-shadow`}
        onClick={action}
        onKeyPress={(e) => (e.key === "Enter" ? action() : undefined)}
        tabIndex={focusable === false ? -1 : 0}
      >
        {loading ? (
          <ProgressCircle
            size="md"
            color={value ? site.primaryContrastColour : site.whiteContrastColour}
          />
        ) : label != null ? (
          <>
            {children != null ? <span>{children}</span> : null}
            <small className={labelClassName}>{label}</small>
          </>
        ) : (
          children
        )}
        <style jsx>{`
          .btn-round {
            background: ${bgHex};
            height: 3.3rem;
            width: 3.3rem;
            cursor: pointer;
            color: ${fgHex};
            outline: none;
            font-size: ${label == null ? "1.5rem" : "1rem"};
          }

          .btn-round.disabled {
            cursor: not-allowed;
            opacity: 0.3;
          }

          small {
            font-size: 65%;
            font-weight: bold;
          }

          .btn-round:hover,
          .btn-round:focus {
            color: ${fgHex};
          }

          .btn-round.on {
            box-shadow: 0 0 0 1px ${shadowColor.fade(0.7).string()};
          }
          .btn-round:not(.disabled):hover,
          .btn-round:not(.disabled):focus,
          .btn-round:not(.disabled).on:focus {
            box-shadow: 0 0 0 1px ${shadowColor.fade(0.7).string()},
              0px 4px 30px rgba(0, 0, 0, 0.4);
          }
          .btn-round.off {
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
          }
          .btn-round.off.always-shadow {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3),
              0px 1px 1px rgba(0, 0, 0, 0.3);
          }
          .btn-round:not(.disabled).off:hover,
          .btn-round:not(.disabled).off:focus {
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.4);
          }
          .btn-round:not(.disabled).off.always-shadow:hover,
          .btn-round:not(.disabled).off.always-shadow:focus {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3),
              0px 4px 30px rgba(0, 0, 0, 0.4);
          }
        `}</style>
      </a>
    </>
  );
};
