import React from "react";

export const Tick: React.FC<{ size?: number }> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      d="M4.65099 8.518L1.28781 5.17709L0.166748 6.29073L4.65099 10.7453L14.2601 1.19982L13.139 0.0861816L4.65099 8.518Z"
    />
  </svg>
)
