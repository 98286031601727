import { formatters, useTranslation } from "@equiem/web-ng-lib";
import { Site } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { OccurrenceSummaryFragmentFragment } from "../../generated/gateway-client";
import { DateTime } from "../../lib/DateTime";
import { Card } from "../Card";
import { CardImage } from "../CardImage";
import { Clock } from "../icons/Clock";
import { Location } from "../icons/Location";
import { InteractionCounts } from "../InteractionCounts";
import { RsvpButton } from "./RsvpButton";

type Occurrence = OccurrenceSummaryFragmentFragment;

interface Props {
  className?: string;
  occurrence: Occurrence;
  focusable?: boolean;
  metric?: {
    ns: string;
    nx: number;
  };
}

export const EventCard: React.FC<Props> = ({ className = "", occurrence, focusable, metric }) => {
  const { i18n } = useTranslation();
  const { timezone } = useContext(Site);
  const startDate = DateTime.fromMillis(occurrence.startDate, { zone: timezone });
  const endDate = DateTime.fromMillis(occurrence.endDate, { zone: timezone });
  const metricExt = metric == null ? "" : `?ns=${metric.ns}&nx=${metric.nx}`;

  return (
    <Card
      img={(
        <CardImage url={occurrence.eventPost?.featuredImage.url}>
          {!occurrence.isRsvpEnabled ? null : <RsvpButton {...occurrence} shadow="hover" focusable={focusable} />}
        </CardImage>
      )}
      href="/event/[uuid]"
      as={`/event/${occurrence.uuid}${metricExt}`}
      title={occurrence.title}
      className={`${className} event-card`}
      focusable={focusable}
    >
      <div className="info">
        <div className="time d-flex">
          <span><Clock /></span>
          <span className="pl-1 icon-label">
            {startDate.hasSame(endDate, "day")
              ? `${formatters.datelong(startDate.valueOf(), i18n.language, { timezone })} ${formatters.timeshort(startDate.valueOf(), i18n.language, { timezone })} - ${formatters.timeshort(endDate.valueOf(), i18n.language, { timezone })}`
              : `${formatters.datelong(startDate.valueOf(), i18n.language, { timezone })} ${formatters.timeshort(startDate.valueOf(), i18n.language, { timezone })} - ${formatters.datelong(endDate.valueOf(), i18n.language, { timezone })} ${formatters.timeshort(endDate.valueOf(), i18n.language, { timezone })}`}</span>
        </div>
        {occurrence.location == null ? null : (
          <div className="location d-flex">
            <span><Location /></span>
            <span className="pl-1 icon-label">{occurrence.location}</span>
          </div>
        )}
        <div className="counts pt-3">
          <InteractionCounts content={occurrence} />
        </div>
      </div>
      <style jsx>{`
        .info {
          margin-top: auto;
        }
        .time, .location {
          font-weight: 600;
        }
      `}</style>
    </Card>
  );
}
