import { DataProxy } from "@apollo/client";
import { TFunction } from "@equiem/web-ng-lib";
import { useShowError } from "@equiem/web-ng-lib";
import {
  CustomerOccurrencesAndSessionsDocument,
  CustomerOccurrencesAndSessionsQuery,
  CustomerOccurrencesAndSessionsQueryVariables,
  RsvpedByMeFragment,
  RsvpedByMeFragmentDoc,
  useAddRsvpMutation,
  useCancelRsvpMutation,
} from "../../generated/gateway-client";

export const useRsvpMutations = ({ occurrenceUuid: uuid, first = 50 }: { occurrenceUuid?: string; first?: number }) => {
  const showError = useShowError();
  const [add, { loading: addingRsvp }] = useAddRsvpMutation();
  const [
    cancel,
    { loading: cancellingRsvp },
  ] = useCancelRsvpMutation();

  const updateCache = (cache: DataProxy, rsvped: boolean) => {
    const id = `Occurrence:${uuid}`;
    const existing = cache.readFragment<RsvpedByMeFragment>({
      id,
      fragment: RsvpedByMeFragmentDoc,
    });
    cache.writeFragment<RsvpedByMeFragment>({
      id: `Occurrence:${uuid}`,
      fragment: RsvpedByMeFragmentDoc,
      data: {
        __typename: "Occurrence",
        rsvpedByMe: rsvped,
        rsvpCount: (existing?.rsvpCount ?? 0) + (rsvped ? 1 : -1),
        hasCapacity: rsvped ? (existing?.hasCapacity ?? true) : true,
      },
    });

    if (!rsvped) {
      const sAndOQuery = cache.readQuery<CustomerOccurrencesAndSessionsQuery, CustomerOccurrencesAndSessionsQueryVariables>({
        query: CustomerOccurrencesAndSessionsDocument,
        variables: { current: true, first },
      });

      if (sAndOQuery != null) {
        cache.writeQuery<CustomerOccurrencesAndSessionsQuery, CustomerOccurrencesAndSessionsQueryVariables>({
          data: {
            myOccurrencesAndSessions: {
              totalCount: sAndOQuery.myOccurrencesAndSessions.totalCount,
              pageInfo: sAndOQuery.myOccurrencesAndSessions.pageInfo,
              edges: sAndOQuery.myOccurrencesAndSessions.edges.filter((edge) => edge?.node?.uuid !== uuid),
            },
          },
          query: CustomerOccurrencesAndSessionsDocument,
          variables: { current: true, first },
        });
      }
    }
  };

  const cancelRsvp = async () => {
    if (uuid == null) {
      return;
    }

    return cancel({
      variables: { occurrenceUuid: uuid },
      update: (cache, result) => {
        if (result.data?.cancelRsvpV2 === true) {
          updateCache(cache, false);
        }
      },
    });
  };

  const addRsvp = async ({ acceptTermsAndConditions }: { acceptTermsAndConditions: boolean }, t: TFunction) => {
    if (uuid == null) {
      return;
    }

    return add({
      variables: {
        occurrenceUuid: uuid,
        acceptTermsAndConditions,
      },
      update: (cache, result) => {
        if (result.data?.addRsvpV2.__typename === "RsvpSuccess") {
          updateCache(cache, true);
        }
        else {
          showError(
            result.data?.addRsvpV2.__typename === "RsvpFailure"
              ? result.data?.addRsvpV2.reason
              : t("common.somethingWrongTryAgainLater"),
          );
        }
      },
    });
  };

  return { addRsvp, cancelRsvp, addingRsvp, cancellingRsvp };
};
